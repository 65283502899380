import "./../views/_menupage.css";
import "./visca.scss";

import { JaaSMeeting } from "@jitsi/react-sdk";
import { RadioGroup } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import logo from "./../images/MEDIMEET.png";

import ShowMenu from "./../images/new/Icons_ShowMenu.svg";
import LogoWhiteboard from "./../images/new/Icons_Whiteboard.svg";
import LogoWhiteboardClose from "./../images/new/Icons_Whiteboard-close.svg";
import LogoMicrophone from "./../images/new/Icons_Microphone-enable.svg";
import LogoWebcam from "./../images/new/Icons_Camera-enable.svg";
import CloseMicrophone from "./../images/new/Icons_Microphone-disable.svg";
import CloseWebcam from "./../images/new/Icons_Camera-diable.svg";
import SnapShowIcon from "./../images/Icons_Capture.svg";
import SnapShowDisableIcon from "./../images/Icons_Capture-disable.svg";
import PTZ from "./../images/new/Icons_PTZ.svg";

let urlSafeWhiteBoard, envJitsiMeetAPI;

document.documentElement.addEventListener(
  "touchstart",
  function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  },
  {
    passive: false,
  }
);

// import { Client } from './../client';
function WhiteBoardToggle() {
  let { id } = useParams();

  urlSafeWhiteBoard =
    process.env.REACT_APP_MMT_URL + "/boards/" + id.toLowerCase();

  return (
    <div className="whiteboard-container">
      <iframe
        src={urlSafeWhiteBoard}
        title="Whiteboard"
        className="whiteboard-iframe"
        id="whiteboardIframe"
      ></iframe>
    </div>
  );
}

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const appId = process.env.REACT_APP_appId;
const jitsiDomain = process.env.REACT_APP_JITSIMEET_URL;
const jwt = process.env.REACT_APP_JWT;
const MeetingRoom = () => {
  let viscaStopEvent = "";
  const directionList = [
    { key: "up", value: "PTZ_UP" },
    { key: "left", value: "PTZ_LEFT" },
    { key: "reset", value: "PTZ_RESET" },
    { key: "right", value: "PTZ_RIGHT" },
    { key: "down", value: "PTZ_DOWN" },
  ];
  const distanceList = [
    { key: "zoomIn", value: "PTZ_ZOOM_IN" },
    { key: "zoomOut", value: "PTZ_ZOOM_OUT" },
  ];

  const params = useParams();

  const appConfig = {
    startWithAudioMuted: false,
    startWithVideoMuted: false,
    disableModeratorIndicator: false,
    // startScreenSharing: true,
    enableEmailInStats: false,
    notifications: !appId ? [] : undefined,
    disabledNotifications: !appId ? undefined : true,
    participantsPane: true,
    prejoinPageEnabled: true,
    brandingRoomAlias: params.id,
    resolution: 1080,
    constraints: {
      video: {
        height: {
          ideal: 1080,
          max: 1080,
          min: 720,
        },
      },
    },
    videoQuality: {
      disabledCodec: "H264",
      preferredCodec: "VP8",
      maxBitratesVideo: {
        H264: {
          low: 1200000,
          standard: 2400000,
          high: 6500000,
        },
        VP8: {
          low: 1200000,
          standard: 2400000,
          high: 6500000,
        },
        VP9: {
          low: 1200000,
          standard: 2400000,
          high: 6500000,
        },
      },
      minHeightForQualityLvl: {
        360: "low",
        720: "standard",
        1080: "high",
      },
      resizeDesktopForPresenter: false,
    },
    disableSimulcast: false,
  };

  const interfaceConfig = {
    LANG_DETECTION: true,
    // lang: "es",
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    HIDE_INVITE_MORE_HEADER: true,
    MOBILE_APP_PROMO: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    ENABLE_AUTH: true,
    ENABLE_PREJOIN_PAGE: true,
    AUTH_TYPE: "jwt",
    TOOLBAR_BUTTONS: [
      "camera",
      // "chat",
      "closedcaptions",
      "desktop",
      "download",
      "embedmeeting",
      "etherpad",
      "feedback",
      "filmstrip",
      "fullscreen",
      "hangup",
      "help",
      "highlight",
      "invite",
      "linktosalesforce",
      "livestreaming",
      "microphone",
      "noisesuppression",
      "participants-pane",
      "profile",
      "raisehand",
      "recording",
      "security",
      "select-background",
      "settings",
      // "shareaudio",
      "sharedvideo",
      "shortcuts",
      "stats",
      "tileview",
      "toggle-camera",
      "videoquality",
      "whiteboard",
    ],
  };

  let isIOS = iOS();
  const cookies = new Cookies();
  // const stampHavePtz = [];

  const { id } = useParams();
  const navigate = useNavigate();

  const [openToolbar, setOpenToolbar] = useState(false);
  const [toggleWBO, setToggleWBO] = useState(false);
  const [toggleAudio, setToggleAudio] = useState(false);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [isPopover, setIsPopover] = useState(false);
  const isMr = useRef(false);

  const [videoDeviceNumber, setVideoDeviceNumber] = useState(0);
  const [audioDeviceNumber, setAudioDeviceNumber] = useState(0);
  const [isFollowme, setIfFollowme] = useState(false);

  const [showWhiteboardClose, setShowWhiteboardClose] = useState(false);

  const [viscaData, setViscaData] = useState([]);
  const [ptzRoom, setPtzRoom] = useState(null);
  const [ptzCamera, setPtzCamera] = useState(null);
  const [joined, setJoined] = useState(false);

  const [snapShotImgUrl, setSnapShotImgUrl] = useState("");

  useEffect(() => {
    if (viscaData.length === 1) {
      ptzRoomChange(viscaData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viscaData]);

  useEffect(() => {
    if (ptzRoom?.ptz) {
      setPtzCamera(ptzRoom.ptz[0]);
    }
  }, [ptzRoom]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let roles = params.get("roles");
    let name = params.get("username");
    let isFollowmes = params.get("isFollowme");
    isMr.current = params.get("isMr") === "True";
    setRole(roles);
    setUsername(name);
    setIfFollowme(isFollowmes);
  }, [role, isFollowme]);

  useEffect(() => {
    if (ptzCamera) {
      viscaSendMsg("PTZ_Start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptzCamera]);

  // Save list from URL Params to Cookies
  const paramsRoomName = () => {
    let roomList = cookies.get("roomList");

    if (roomList === undefined) {
      if (roomList !== "") {
        roomList = [roomList];
        cookies.set("roomList", roomList, { path: "/", maxAge: 31536000 });
      }
    } else {
      if (roomList.includes(id) === false) {
        if (id !== "") {
          cookies.set("roomList", roomList, { path: "/", maxAge: 31536000 });
          roomList.push(id);
          cookies.set("roomList", roomList, { path: "/", maxAge: 31536000 });
        }
      }
    }
  };

  // Listen Audio Btn and Video Button
  function audioBtn() {
    setToggleAudio((e) => !e);
    envJitsiMeetAPI.executeCommand("toggleAudio");
  }

  // 開關視訊 按鈕 Function
  function videoBtn() {
    setToggleVideo((e) => !e);
    envJitsiMeetAPI.executeCommand("toggleVideo");
  }

  // 强制所有人関白板 Moderator Only
  function closeWhiteboardClick() {
    let wFrame = document.getElementById("whiteboardIframe");
    wFrame.contentWindow.postMessage("clearWhiteboard", urlSafeWhiteBoard);

    setTimeout(() => {
      sendChatMessage(eventMsgEncode("Stop Whiteboard"));
      setToggleWBO(false);
      setShowWhiteboardClose(false);
    }, 100);
  }

  function wboBtn() {
    setOpenToolbar(false);
    if (!toggleWBO) {
      sendChatMessage(eventMsgEncode("Start Whiteboard"));
      setToggleWBO(true);
    } else {
      if (role === "Moderator") {
        setShowWhiteboardClose(true);
      } else {
        hideWhiteboard();
      }
    }
  }

  function saveImage(url) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", `${process.env.REACT_APP_MMT_URL}/upload`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.response);
        const imgUrl =
          process.env.REACT_APP_MMT_URL + JSON.parse(xhr.responseText).url;
        setSnapShotImgUrl(imgUrl);
        sendChatMessage(eventMsgEncode("BG Image", imgUrl));
      }
    };
    xhr.send(JSON.stringify({ image: url, room: window.location.pathname }));
  }

  function anapShotBtn() {
    if (!!snapShotImgUrl) {
      setSnapShotImgUrl("");
      sendChatMessage(eventMsgEncode("BG Image", ""));
    } else {
      envJitsiMeetAPI.captureLargeVideoScreenshot().then((data) => {
        if (data.dataURL) {
          saveImage(data.dataURL);
        }
      });
    }
  }

  // visca開關
  function viscaBtn() {
    if (!!snapShotImgUrl) {
      setIsPopover(true);
      return;
    }

    if (viscaData.length > 0) {
      setViscaData([]);
      ptzRoomChange(null);
      setPtzCamera(null);
    } else {
      sendChatMessage(eventMsgEncode("HavePtz"));
    }
  }

  function ptzRoomChange(e) {
    if (!!snapShotImgUrl) {
      setIsPopover(true);
      return;
    }

    if (ptzCamera) {
      viscaSendMsg("PTZ_End");
      setPtzCamera(null);
    }
    setPtzRoom(e);
  }

  /**相機移動 */
  function cameraDirection(e) {
    viscaStopEvent = "PTZ_PANTILE_STOP";
    viscaSendMsg(e.value);
  }

  /**相機縮放 */
  function cameraDistance(e) {
    viscaStopEvent = "PTZ_ZOOM_STOP";
    viscaSendMsg(e.value);
  }

  /**解除鎖定相機(MR切回原畫面) */
  function cameraControlEnd() {
    if (viscaStopEvent === "") return;
    viscaSendMsg(viscaStopEvent);
    viscaStopEvent = "";
  }

  function viscaSendMsg(controlMsg) {
    if (!!snapShotImgUrl) {
      setIsPopover(true);
      return;
    }

    const msg = eventMsgEncode(
      "cameraControl",
      `${controlMsg}@${
        controlMsg !== "PTZ_End" ? ptzCamera.cameraId + "/" : ""
      }${ptzRoom.displayName}`
    );
    sendChatMessage(msg, ptzRoom.displayId);
  }

  /**
   * 發送chatMessage
   * @param {*} message 訊息
   * @param {*} to 私訊id
   * @param {*} ignorePrivacy 忽略隱私權通知
   */
  function sendChatMessage(message, to = "", ignorePrivacy = true) {
    envJitsiMeetAPI.executeCommand(
      "sendChatMessage",
      message,
      to,
      ignorePrivacy
    );
  }

  /**chat訊息事件encode */
  function eventMsgEncode(event, Message) {
    return window.btoa(JSON.stringify({ key: event, msg: Message }));
  }

  paramsRoomName();
  // let BGImg = "";
  const JitsiAPI = (JitsiMeetAPI) => {
    envJitsiMeetAPI = JitsiMeetAPI;
    window.onmessage = function (e) {
      try {
        const data = JSON.parse(e.data);

        switch (data?.event) {
          case "RoomPtz":
            const msgData = data.msg;
            const msg = eventMsgEncode("getRoomPtz", msgData);
            sendChatMessage(msg, msgData.controlParticipantId);
            break;
          default:
            break;
        }
      } catch (error) {}
    };

    JitsiMeetAPI.addEventListener("participantLeft", function (res) {
      setViscaData((e) => e.filter((list) => list.displayId !== res.id));
    });

    // 偵聽 Chat Message / BroadCast to All Participant
    JitsiMeetAPI.addEventListener("incomingMessage", function (res) {
      try {
        const data = JSON.parse(window.atob(res.message));
        switch (data.key) {
          case "Start Whiteboard":
            setToggleWBO(true);
            break;

          case "Stop Whiteboard":
            setToggleWBO(false);
            break;

          case "BG Image":
            setSnapShotImgUrl(data.msg);
            break;

          case "HavePtz":
            if (isMr.current) {
              if (!res.stamp) {
                window.chrome.webview.postMessage({
                  ptzEvent: data.key,
                  controlParticipantId: res.from,
                });
              }
              // else {
              //   // 接收舊訊息 過濾重複id(from)
              //   if (!stampHavePtz.includes(res.from)) {
              //     stampHavePtz.push(res.from);
              //     window.chrome.webview.postMessage({
              //       ptzEvent: data.key,
              //       controlParticipantId: res.from,
              //     });
              //   }
              // }
            }
            break;
          case "getRoomPtz":
            setViscaData((e) => [
              ...e,
              {
                displayId: res.from,
                displayName: res.nick,
                ptz: data.msg.ptzs,
              },
            ]);
            break;
          case "cameraControl":
            if (isMr.current) {
              window.chrome.webview.postMessage(data.msg);
            }
            break;

          default:
            break;
        }
      } catch (error) {}
    });

    JitsiMeetAPI.addEventListener("audioMuteStatusChanged", function (event) {
      setToggleAudio(!event.muted);
    });

    JitsiMeetAPI.addEventListener("videoMuteStatusChanged", function (event) {
      setToggleVideo(!event.muted);
    });

    // Local User Join Meeting
    JitsiMeetAPI.addListener("videoConferenceJoined", function (event) {
      if (isFollowme === "true") {
        JitsiMeetAPI.executeCommand("setFollowMe", { value: true });
        JitsiMeetAPI.executeCommand("pinParticipant", event.id);
      }
      JitsiMeetAPI.executeCommand("setTileView", false);

      // 取得 Audio Video Device
      // # audioInput
      // # videoInput
      JitsiMeetAPI.getAvailableDevices().then((devices) => {
        setAudioDeviceNumber(devices.audioInput.length);
        setVideoDeviceNumber(devices.videoInput.length);
        setJoined(true);
      });
    });

    JitsiMeetAPI.addListener("videoConferenceLeft", function () {
      navigate("/");
    });

    // Local User Leave Meeting
    JitsiMeetAPI.addListener("readyToClose", function () {
      JitsiMeetAPI.removeListener("videoConferenceJoined", function () {});
      JitsiMeetAPI.removeListener("videoConferenceLeft", function () {});
      JitsiMeetAPI.removeListener("incomingMessage", function () {});
      JitsiMeetAPI.removeListener("audioMuteStatusChanged", function () {});
      JitsiMeetAPI.removeListener("videoMuteStatusChanged", function () {});
    });
  };

  // const changeImage = (url) => {
  //   let wFrame = document.getElementById("whiteboardIframe");
  //   wFrame.contentWindow.postMessage(
  //     JSON.stringify({ img: url, room: id.toLowerCase() }),
  //     urlSafeWhiteBoard
  //   );
  // };

  const hideWhiteboard = () => {
    setToggleWBO(false);
    setShowWhiteboardClose(false);
  };

  return (
    <div
      onMouseUp={() => {
        setIsPopover(false);
        cameraControlEnd();
      }}
    >
      {/* 若偵測是Mobile Device */}
      {isMobile ? (
        <div className="app-detect">
          <header>
            <img src={logo} alt={"logo"} />
          </header>
          <div className="device-container">
            {/* 您需要在手機上安裝MEDIMEET 行動應用程式才能加入這場會議<br></br>
            如果您已經有App了： */}
            <p>
              You need the MEDIMEET mobile app to join this meeting on your
              phone.
            </p>
            <p>If you already have the app</p>

            <a
              href={"medimeet://mmt-web-test.azurewebsites.net/" + id}
              target={"_top"}
            >
              <span>Join this meeting using the app</span>
            </a>

            <p>If you don't have the app yet.</p>
            <a
              href={
                isIOS
                  ? "https://apps.apple.com/app/medimeet/id1537073383"
                  : "https://play.google.com/store/apps/details?id=tw.com.twinbeans.metandroid"
              }
              target="_top"
            >
              <span>Download the app</span>
            </a>
          </div>
        </div>
      ) : (
        <div className="container-iframe">
          {joined && (
            <div>
              <div className="toolbar-mask"></div>
              <div className="mobile-toolbox">
                <button
                  className={"toolbar-btn" + (toggleWBO ? " active" : "")}
                  onClick={wboBtn}
                >
                  <img
                    src={toggleWBO ? LogoWhiteboardClose : LogoWhiteboard}
                    alt={toggleWBO ? "whiteboardClose" : "whiteboard"}
                  />
                </button>
              </div>
              <div
                className={
                  "toolbar-container pc-toolbox" +
                  (openToolbar ? " active" : "")
                }
              >
                <img
                  src={ShowMenu}
                  alt="ShowMenu"
                  className={"show-menu-icon" + (openToolbar ? " active" : "")}
                  onClick={() => setOpenToolbar((openToolbar) => !openToolbar)}
                />

                <button
                  className={"toolbar-btn" + (toggleWBO ? " active" : "")}
                  onClick={wboBtn}
                >
                  <img
                    src={toggleWBO ? LogoWhiteboardClose : LogoWhiteboard}
                    alt={toggleWBO ? "whiteboardClose" : "whiteboard"}
                  />
                </button>

                <div
                  className={
                    "toolbar-btn popoverbox" + (toggleWBO ? "" : " hide")
                  }
                >
                  <button onClick={anapShotBtn}>
                    <img
                      src={snapShotImgUrl ? SnapShowDisableIcon : SnapShowIcon}
                      alt={snapShotImgUrl ? "snapShotDisable" : "SnapShot"}
                    />
                  </button>
                  <div
                    className={
                      "popover-palen " +
                      (isPopover ? "opacity-100" : "opacity-0")
                    }
                  >
                    Close！
                  </div>
                </div>

                <button
                  className={
                    "toolbar-btn" +
                    (toggleWBO ? "" : " hide") +
                    (audioDeviceNumber > 0 ? "" : " disabled")
                  }
                  onClick={audioBtn}
                >
                  <img
                    src={
                      toggleAudio && audioDeviceNumber > 0
                        ? LogoMicrophone
                        : CloseMicrophone
                    }
                    alt="AudioButton"
                  />
                </button>
                <button
                  className={
                    "toolbar-btn" +
                    (toggleWBO ? "" : " hide") +
                    (videoDeviceNumber > 0 ? "" : " disabled")
                  }
                  onClick={videoBtn}
                >
                  <img
                    src={
                      toggleVideo && videoDeviceNumber > 0
                        ? LogoWebcam
                        : CloseWebcam
                    }
                    alt="WebcamButton"
                  />
                </button>

                <button
                  className={
                    "toolbar-btn" + (viscaData.length > 0 ? " active" : "")
                  }
                  onClick={viscaBtn}
                >
                  <img src={PTZ} alt="" />
                </button>
              </div>
            </div>
          )}

          {!!appId ? (
            <JaaSMeeting
              appId={appId}
              jwt={role === "Moderator" ? jwt : undefined}
              roomName={id}
              configOverwrite={appConfig}
              userInfo={{
                displayName: username,
              }}
              interfaceConfig={interfaceConfig}
              interfaceConfigOverwrite={interfaceConfig}
              onApiReady={JitsiAPI}
            />
          ) : (
            <JaaSMeeting
              domain={jitsiDomain}
              jwt={role === "Moderator" ? jwt : undefined}
              roomName={id}
              configOverwrite={appConfig}
              userInfo={{
                displayName: username,
              }}
              interfaceConfig={interfaceConfig}
              interfaceConfigOverwrite={interfaceConfig}
              onApiReady={JitsiAPI}
            />
          )}

          {toggleWBO && snapShotImgUrl && (
            <img
              src={snapShotImgUrl}
              className="w-full h-full absolute top-0 left-0"
              alt=""
            />
          )}

          {toggleWBO && <WhiteBoardToggle />}
        </div>
      )}
      <div
        className={
          "absolute bottom-6 left-6 " +
          (viscaData.length > 0 ? "flex" : "hidden")
        }
      >
        <div className="p-2 bg-dark-base">
          {viscaData.length > 0 && (
            <RadioGroup
              value={ptzRoom}
              onChange={ptzRoomChange}
              className="flex flex-col gap-2"
            >
              {viscaData.map((plan) => (
                <RadioGroup.Option
                  key={plan.displayId}
                  value={plan}
                  className={({ active, checked }) =>
                    `${
                      checked ? "bg-sky-900/75" : ""
                    } cursor-pointer rounded-md px-1 py-0.5 text-sm text-white`
                  }
                >
                  <RadioGroup.Label>{plan.displayName}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          )}
        </div>

        <div className={"viscaBox " + (!ptzRoom ? "hidden" : "grid")}>
          <div className="cameraBox">
            {ptzRoom && (
              <RadioGroup
                value={ptzCamera}
                onChange={setPtzCamera}
                className="flex gap-2"
              >
                {ptzRoom.ptz.map((plan) => (
                  <RadioGroup.Option
                    key={plan.cameraId}
                    value={plan}
                    checked={ptzCamera === plan}
                    className={({ active, checked }) =>
                      `${checked ? "bg-sky-900/75" : ""}
                    cursor-pointer rounded-md px-1 py-0.5 text-sm text-white`
                    }
                  >
                    <RadioGroup.Label>{plan.cameraName}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            )}
          </div>
          <div className="directionBox">
            {directionList.map((e) => (
              <button
                key={e.key}
                className={e.key}
                onMouseDown={() => cameraDirection(e)}
              ></button>
            ))}
          </div>
          <div className="distanceBox">
            {distanceList.map((e) => (
              <button
                key={e.key}
                className={e.key}
                onMouseDown={() => cameraDistance(e)}
              ></button>
            ))}
          </div>
        </div>
      </div>
      <div
        className={"modal-mask" + (!showWhiteboardClose && " hidden")}
        onClick={() => setShowWhiteboardClose(false)}
      >
        <div className="modal-show-whiteboard">
          <button onClick={hideWhiteboard}>Hide Whiteboard</button>
          <button onClick={closeWhiteboardClick}>End Whiteboard</button>
        </div>
      </div>
    </div>
  );
};

export default MeetingRoom;
